import DynamicWidget from '@/components/dynamicWidget';
import {withDefaultprops, wrapper} from '@/store';
import {getWidgets} from '@/store/services';
import React from 'react';
import {useLanguage} from '@/hooks/LanguageContext';
import Head from 'next/head';

const Home = pageProps => {
  const {selectedLanguage} = useLanguage();
  return (
    <>
      <Head>
        <title>Boyner Kurumsal</title>
      </Head>
      <DynamicWidget serviceParam={{type: 8, name: 'kurumsalhome', platform: '1,2,3', lang: selectedLanguage}} {...pageProps} />
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(
  store => async context =>
    withDefaultprops(store, context, async language => {
      const {dispatch} = store;
      return await Promise.all([await dispatch(getWidgets.initiate({type: 8, name: 'kurumsalhome', platform: '1,2,3', lang: language}))]);
    }),
);

export default Home;
